(function( $ ) {
  $(function() {
      // code using $ as alias to jQuery
      getDropzoneFile();
      function getDropzoneFile() {
          $('.dropzone').each(function(i){
              var dzVal = $(this).prev('input[type=hidden]').attr('value');
              if (dzVal){
                  //var index = dzVal.lastIndexOf("/") + 1;
                  //var filename = dzVal.substr(index);

                  //$(this).find('.filename-dz').text(filename);
                  $(this).addClass('not-empty');
              }
              $(this).find('.icon-remove-system').click(function (evt) {
                  // delete the file that is stored in this field
                  deleteFile($(this).data('item'));
                  evt.preventDefault();
                  return false;
              });
          });
      }
  });

})(jQuery);
