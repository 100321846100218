/**
 * Building blocks consisting of multiple sections contain spacers which separate the sections:
 * <div class="control-group control-spacer_1">...</div>
 * The goal is to hide all but one empty sections
 */
(function( $ ) {
  $(function() {

      // hide all next after current spacer
      var currentSpacer = 1, // section id
          currentInput,
          currentEditor;

      if($('.view-block') || $('.view-offer')) {
          addClearBtns ();
          getCurrentSpacer();
      }

      function addClearBtns () {
          $('[class*="control-spacer"]').each(function(i) {
              addClearBtn(this, i);
          })
      }

      function getCurrentSpacer() {
          $('[class$="spacer_'+currentSpacer+'"]').each(function() {

              currentInput = getInputBySpacerId(this, currentSpacer);
              currentEditor = getTextareaBySpacerId(currentSpacer);

              if (currentInput.val() || currentEditor.val()) {
                  currentSpacer++;
                  getCurrentSpacer();
              }
              else {
                  getNextSpacer();
              }
              return(false);
          });
      }

      function getNextSpacer() {
          $('[class*="spacer_'+currentSpacer+'"]').each(function() {
              $(this).removeClass('hidden');
              $(this).prevAll().removeClass('hidden');

              // attach event handler to the input fields of the current spacer. eg.: .control-url_1 > input
              currentInput = getInputBySpacerId(this, currentSpacer);
              currentInput.on("change", function(){
                  setNextSpacer();
              });
              // attach event handler to jce editor textareas of the current spacer. eg.: #jform_body_1 / #jform_answer_2
              currentEditor = getTextareaBySpacerId(currentSpacer);
              currentEditor.on("change", function(){
                  setNextSpacer();
              });

              $(this).nextAll().addClass('hidden');
              return(false);
          });
      }

      function setNextSpacer() {
          //remove event handlers
          currentInput.off("change");
          currentEditor.off("change");
          //change current spacer
          currentSpacer++;
          getNextSpacer();
      }

      function setPreviousSpacer() {
          //remove event handlers
          currentInput.off("change");
          currentEditor.off("change");
          //change current spacer
          currentSpacer--;
          getNextSpacer();
      }

      function getInputBySpacerId(spacer, id) {
          return $(spacer).prevAll('[class$="_'+id+'"]').find('input');
      }

      function getTextareaBySpacerId(id) {
          return $('textarea[id$="'+id+'"]');
      }

      function addClearBtn (spacer, id) {
          var clearBtn = $("<span data-id='"+ id +"' class='js-clear-btn hidden'>&times;</span>");
          clearBtn.on("click", function () {
              var id = $(this).data('id');
              clearSection(id);
          });
          var firstElemenInSection = $(spacer).prevAll('[class*="_'+id+'"]').last();
          firstElemenInSection.before( clearBtn );
      }

      /**
       *
       */
      function clearSection(id) {

          // for each section starting with the one to be cleared/deleted:
          // get elements with next id
          // copy their content over to corresponding elements with current id
          // clear fields of the last section

          var nextAll = $('[class*="control-spacer"]');

          // shift content of sections by 1
          nextAll.each(function() {
              var regex = /(.*)(_)(\d*)(.*)/;
              var classNameParts = this.className.match(regex);
              var elId = Number(classNameParts[3]);
              if (elId < id) return; // start clearing from the button which was clicked

              var nextId = elId + 1;

              var currentInput = $(this).prevAll('[class*="_'+elId+'"]').find('input');
              // for each <input/> in the current section...
              $(currentInput).each(function() {
                  // get an identifier for the current input field; eg jform[title_4]
                  // use the name as identifier; assuming all inputs have a name (required for form submission)
                  var idAttr = 'name',
                      identifier = $(this).attr(idAttr),
                      idParts = identifier.match(regex);

                  // use the identifier to get the corresponding input field (id + 1); eg jform[title_5]
                  var nextIdentifier = idParts[1] + idParts[2] + nextId + idParts[4];
                  var nextInput = $('[' + idAttr + '="' + nextIdentifier + '"]');
                  var foundNextInput = Boolean(nextInput.length);

                  if (foundNextInput) {
                      $(this).val(nextInput.val());
                  } else {
                      $(this).val('');
                  }

                  var sibling = $(this).next();
                  if(sibling.hasClass('dropzone')) {
                      var nextDropzone = nextInput.next(),
                          notEmptyClass = 'not-empty',
                          withPreviewClass = 'dz-started',
                          fileIcon = sibling.find('.icon-file');

                      if (foundNextInput && nextInput.val()) {
                          sibling.addClass(notEmptyClass);

                          var nextFileIcon = nextDropzone.find('.icon-file');
                          var nextPreview = nextDropzone.find('.dz-preview');

                          if (nextFileIcon) {
                              // copy over all icon classnames (they differ per file type)
                              fileIcon.attr('class', nextFileIcon.attr('class'));
                          }

                          sibling.find('.dz-preview').remove();
                          if (nextPreview.length) {
                              // copy over any preview elements of the file
                              sibling.addClass(withPreviewClass);
                              sibling.append(nextPreview);
                          } else {
                              sibling.removeClass(withPreviewClass);
                          }

                      } else {
                          // next dropzone doesn't exists or is empty
                          sibling.removeClass(notEmptyClass + ' ' + withPreviewClass);
                          fileIcon.removeClass().addClass('icon icon-file icon-dropzone-image');
                          sibling.find('.dz-preview').remove();
                      }
                  }
              });

              // plain text <textarea/>
              var currentTextarea = $(this).prevAll('[class*="_'+elId+'"]').find('textarea:not("mce_editable")');
              $(currentTextarea.each(function() {

                  var idAttr = 'name',
                      identifier = $(this).attr(idAttr),
                      idParts = identifier.match(regex);

                  // use the identifier to get the corresponding input field (id + 1); eg jform[title_5]
                  var nextIdentifier = idParts[1] + idParts[2] + nextId + idParts[4];
                  var nextTextarea = $('[' + idAttr + '="' + nextIdentifier + '"]');
                  var foundNextTextarea = Boolean(nextTextarea.length);

                  if (foundNextTextarea) {
                      $(this).val(nextTextarea.val());
                  } else {
                      // this should be the last section; clear content
                      $(this).val('');
                  }
              }));

              // tinymce <textarea/>
              var currentEditor = $('textarea[id$="' + elId + '"]').attr('id');
              if (currentEditor && typeof tinymce !== 'undefined') {
                  var nextEditor = $('textarea[id$="' + nextId + '"]').attr('id');

                  if (typeof nextEditor !== 'undefined') {
                      var nextContent = tinymce.get(nextEditor).getContent();
                      $(currentEditor).html(nextContent);
                      tinymce.get(currentEditor).setContent(nextContent);
                  } else {
                      // this should be the last section; clear content
                      $(currentEditor).html('');
                      tinymce.get(currentEditor).setContent('');
                  }
              }

          });

          setPreviousSpacer();
      }
  });

})(jQuery);