/**
 * Add colored label icons to "chzn" select elements with the data attribute 'color'
 * eg. <option value="48" data-color="#2f34cc">name</option>
 */
(function( $ ) {
  $(function() {

      // update active class.
      var chznContainer = $('.chzn-container'),
          labelMargin = ';margin-right: 15px;';

      if ((chznContainer).length) {
          chznContainer.each(function() {
              var chznActiveEl = $(this).find('.chzn-single'),
                  chznSelected = $(this).prev("select").find("option:selected"),
                  chznSelectedClass = chznSelected.attr('class'),
                  chznSelectedLabelColor = chznSelected.data('color'),
                  hasLabel = false;

              chznActiveEl.addClass(chznSelectedClass);

              if( chznSelectedLabelColor ) {
                  var selectedLabel = $( "<i class='icon-tag' style='color:" + chznSelectedLabelColor + labelMargin +"float:left;'></i>");
                  chznActiveEl.prepend(selectedLabel);
                  hasLabel = true;
              }

              $(this).prev("select").change(function() {
                  chznSelected = $(this).find("option:selected");
                  chznActiveEl.removeClass(chznSelectedClass);
                  chznSelectedClass = chznSelected.attr('class');
                  chznSelectedLabelColor = chznSelected.data('color');
                  chznActiveEl.addClass(chznSelectedClass);

                  if( chznSelectedLabelColor && hasLabel === false ) {
                      var selectedLabel = $( "<i class='icon-tag' style='color:" + chznSelectedLabelColor + labelMargin +"float:left;'></i>");
                      chznActiveEl.prepend(selectedLabel);
                      hasLabel = true;
                  }

                  if( chznSelectedLabelColor && hasLabel === true  ) {
                      chznActiveEl.find('i').css('color', chznSelectedLabelColor);
                  }

                  if( !chznSelectedLabelColor && hasLabel === true  ) {
                      chznActiveEl.find('i').remove();
                      hasLabel = false;
                  }
              });
          });

          var labelSelectbox = $('.labels-select');
          if (labelSelectbox.length) {
              $('.labels-select + .chzn-container').on('click', function(ev, params){

                  $('.chzn-results li').each(function(){

                      var $labelIndex = $(this).data('optionArrayIndex'),
                          $labelColor = $(this).closest('.chzn-container').prev('select').find("option").eq($labelIndex).data('color'),
                          $newLabel = $( "<i class='icon-tag' style='color:" + $labelColor + ";" + labelMargin + "'></i>");

                      $(this).prepend($newLabel);
                  });
              });
          }
      }

  }); // more jQuery code..

})(jQuery);